import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import BezLedaDiscordBot from './components/BezLedaDiscordBot';
import CyberCorpChaosBot from './components/CyberCorpChaosBot';
import AinsLiberry from './components/AinsLiberry';

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <header className="header">
          <Link to="/" className="title-link">
            <h1>Bez Leda Studios - Documentation</h1>
          </Link>
        </header>

        <hr />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/bez-leda-discord-bot" element={<BezLedaDiscordBot />} />
          <Route path="/cyber-corp-chaos-bot" element={<CyberCorpChaosBot />} />
          <Route path="/ains-liberry" element={<AinsLiberry />} />
          {/* Add more project routes as needed */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;

