import React from 'react';

const Conclusion = ({ title, content }) => {
    return (
    <section>
      <h2>{title}</h2>
      {content.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
    </section>
  );
};

export default Conclusion;
