import React from 'react';

const CommandExamples = ({ examples }) => {
  return (
    <section>
      <h2>Command Examples</h2>
      {examples.map((example) => (
        <div key={example.command}>
          <h3>{example.command}</h3>
          <p>{example.description}</p>
          {/* You can format and display additional examples or outputs here */}
        </div>
      ))}
    </section>
  );
};

export default CommandExamples;
