import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="project-grid">
      <Link to="/bez-leda-discord-bot" className="project-button">
        Bez Leda Discord Bot
      </Link>
      <Link to="/cyber-corp-chaos-bot" className="project-button">
        Cyber Corp Chaos Bot
      </Link>
      <Link to="/ains-liberry" className="project-button">
        Ain's Liberry
      </Link>
      {/* Add more project buttons as needed */}
    </div>
  );
};

export default Home;
