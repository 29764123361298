import React from 'react';
import '../Documentation.css';
import Introduction from './Introduction';
import AvailableCommands from './AvailableCommands';
import CommandExamples from './CommandExamples';
import AdditionalFeatures from './AdditionalFeatures';
import FAQ from './FAQ';
import Conclusion from './Conclusion';

import bezLedaData from '../data/bezLedaData'; // Import data for Bez Leda bot

const BezLedaDiscordBot = () => {
  const { introduction, availableCommands, commandExamples, additionalFeatures, faq, conclusion } = bezLedaData;

  // Function to handle smooth scrolling
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="page-container">
      <aside className="sidebar">
        <ul>
          <li>
            <button onClick={() => scrollToSection('introduction')}>Introduction</button>
          </li>
          <li>
            <button onClick={() => scrollToSection('available-commands')}>Available Commands</button>
          </li>
          <li>
            <button onClick={() => scrollToSection('command-examples')}>Command Examples</button>
          </li>
          <li>
            <button onClick={() => scrollToSection('additional-features')}>Additional Features</button>
          </li>
          <li>
            <button onClick={() => scrollToSection('faq')}>Frequently Asked Questions</button>
          </li>
          <li>
            <button onClick={() => scrollToSection('conclusion')}>Conclusion</button>
          </li>
        </ul>
      </aside>

      <main className="main-content">
        {/* Add id attributes to each section */}
        <div id="introduction">
          <Introduction title={introduction.title} description={introduction.description} link={introduction.link} />
        </div>
        <hr /> {/* Horizontal line after Introduction */}
        <div id="available-commands">
          <AvailableCommands commands={availableCommands} />
        </div>
        <hr /> {/* Horizontal line after Available Commands */}
        <div id="command-examples">
          <CommandExamples examples={commandExamples} />
        </div>
        <hr /> {/* Horizontal line after Command Examples */}
        <div id="additional-features">
          <AdditionalFeatures features={additionalFeatures} />
        </div>
        <hr /> {/* Horizontal line after Additional Features */}
        <div id="faq">
          <FAQ faqItems={faq} />
        </div>
        <hr /> {/* Horizontal line after FAQ */}
        <div id="conclusion">
          <Conclusion title={conclusion.title} content={conclusion.content} />
        </div>
      </main>
    </div>
  );
};

export default BezLedaDiscordBot;
