import React from 'react';

const FAQ = ({ faqItems }) => {
  return (
    <section>
      <h2>Frequently Asked Questions (FAQ)</h2>
      <ul style={{ listStyle: 'none', padding: 0 }}>
        {faqItems.map((faq, index) => (
          <li key={faq.question} style={{ marginBottom: '20px' }}>
            <strong>Q: {faq.question}</strong>
            <br />
            <span style={{ marginLeft: '15px' }}>A: {faq.answer}</span>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default FAQ;
