import React from 'react';

const Introduction = ({ title, description, link }) => {
  return (
    <section>
      <h2>{title}</h2>
      {description.map((line, index) => (
        <p key={index}>{line}</p>
      ))}
      <p>
        <a href={link} target="_blank" rel="noopener noreferrer">
          {link}
        </a>
      </p>    </section>
  );
};

export default Introduction;
