import React from 'react';

const AvailableCommands = ({ commands }) => {
  return (
    <section>
      <h2>Available Commands</h2>
      <ul>
        {commands.map((commandData) => (
          <li key={commandData.command}>
            <strong>{commandData.command}</strong>: {commandData.description}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default AvailableCommands;
