import React from 'react';

const AdditionalFeatures = ({ features }) => {
  return (
    <section>
      <h2>Additional Features</h2>
      {features.map((feature) => (
        <div key={feature.title}>
          <h3>{feature.title}</h3>
          <p>{feature.description}</p>
          {/* You can add more details or formatting for each feature here */}
        </div>
      ))}
    </section>
  );
};

export default AdditionalFeatures;
