const bezLedaData = {
    introduction: {
        title: "Bez Leda Discord Bot",
        description: [
          "Welcome to the Bez Leda Discord Bot, exclusively designed and tailored for TheCyberFlash's vibrant and dynamic Discord community! This powerful and versatile bot is packed with exciting features to keep our server engaged and entertained.",
          "As one of the cornerstones of our server, Bez Leda aims to bring fun, utility, and interactivity to every member's Discord experience. Imagine a bot that not only fetches the latest YouTube videos from TheCyberFlash's channel but also provides a link to the streaming schedule, so you never miss a live session. Bez Leda keeps you informed and connected, displaying the total number of YouTube subscribers and the progress towards subscriber goals. Exciting, isn't it?",
          "But Bez Leda is not just about YouTube; it's about fostering a sense of community through shared experiences. With an extensive list of games to play together, members can engage in friendly competitions and lighthearted challenges. Feel free to add your favorite games to the list and make the server even more enjoyable for everyone.",
          "For our diligent server moderators, Bez Leda offers powerful moderation tools, ensuring a smooth and harmonious server environment. From managing members to handling custom welcome messages, our bot has got it all covered.",
          "The centerpiece of fun and interaction, the Rock Paper Scissors game, is bound to bring laughter and rivalry among members. Compete for a spot in the top 10 leaderboard and showcase your skills to the entire community!",
          "One of Bez Leda's unique features is the periodic posting of random YouTube videos from TheCyberFlash's channel. Surprise and delight your fellow members with unexpected content, creating unforgettable moments together.",
          "Join TheCyberFlash's Discord server and get ready to experience the magic of Bez Leda Discord Bot. Embrace a world of camaraderie, creativity, and continuous fun, all powered by this exceptional bot. Together, let's elevate our server to new heights with Bez Leda!",
        ],
        link: "https://github.com/TheCyberFlash/TheCyberFlash-Youtube-Discord-Bot",
      },
    availableCommands: [
        {
          command: "!info",
          description: "Displays information about the bot and available commands.",
        },
        {
          command: "!channel or !link",
          description: "Provides a link to TheCyberFlash's YouTube channel <https://www.youtube.com/c/CyberFlash/>.",
        },
        {
          command: "!latest",
          description: "Retrieves the link to the latest YouTube video on TheCyberFlash's channel.",
        },
        {
          command: "!subs",
          description: "Displays the total number of subscribers TheCyberFlash has on YouTube.",
        },
        {
          command: "!subgoal",
          description: "Shows the progress towards the subscriber goal.",
        },
        {
          command: "!memgoal",
          description: "Shows the progress towards the member goal.",
        },
        {
          command: "!schedule",
          description: "Provides the streaming schedule for TheCyberFlash's YouTube channel.",
        },
        {
          command: "!games",
          description: "Provides a list of games we can play together.",
        },
        {
          command: "!games add",
          description: "Add a game to the list. (Admin only).",
        },
        {
          command: "!games remove",
          description: "Remove a game from the list. (Admin only).",
        },
        {
          command: "!games random",
          description: "Randomly selects a game from the list.",
        },
        {
          command: "!rps (rock/paper/scissors)",
          description: "Play Rock Paper Scissors with the bot.",
        },
        {
          command: "!leaderboard",
          description: "Displays the top 10 players based on points, wins, and losses in the Rock Paper Scissors game.",
        },
        {
          command: "!reset",
          description: "Resets the scores of all players in the Rock Paper Scissors game. (Admin only)",
        },
        {
          command: "!reset @username:",
          description: "Resets the scores of the specified user in the Rock Paper Scissors game. (Admin only)",
        },
      ],
      
      commandExamples: [
        {
          command: "!info",
          description: "Example: !info\nOutput: Bez Leda Discord Bot - Version 1.0.0\nAvailable Commands:\n- !info\n- !channel or !link\n- !latest (video)\n- !subs\n- !subgoal\n- !memgoal\n- !schedule\n- !games\n- !games add\n- !games remove\n- !games random\n- !rps (rock/paper/scissors)\n- !leaderboard\n- !reset\n- !reset @username:",
        },
        {
          command: "!channel or !link",
          description: "Example: !channel\nOutput: Provides a link to TheCyberFlash's YouTube channel: [https://www.youtube.com/c/CyberFlash/]",
        },
        {
          command: "!latest",
          description: "Example: !latest\nOutput: Retrieves the link to the latest YouTube video on TheCyberFlash's channel: [YouTube Video Link]",
        },
        {
          command: "!subs",
          description: "Example: !subs\nOutput: Displays the total number of subscribers TheCyberFlash has on YouTube.",
        },
        {
          command: "!subgoal",
          description: "Example: !subgoal\nOutput: Shows the progress towards the subscriber goal.",
        },
        {
          command: "!memgoal",
          description: "Example: !memgoal\nOutput: Shows the progress towards the member goal.",
        },
        {
          command: "!schedule",
          description: "Example: !schedule\nOutput: Provides the streaming schedule for TheCyberFlash's YouTube channel.",
        },
        {
          command: "!games",
          description: "Example: !games\nOutput: Provides a list of games we can play together.",
        },
        {
          command: "!games add",
          description: "Example: !games add\nOutput: Add a game to the list. (Admin only).",
        },
        {
          command: "!games remove",
          description: "Example: !games remove\nOutput: Remove a game from the list. (Admin only).",
        },
        {
          command: "!games random",
          description: "Example: !games random\nOutput: Randomly selects a game from the list.",
        },
        {
          command: "!rps (rock/paper/scissors)",
          description: "Example: !rps rock\nOutput: Play Rock Paper Scissors with the bot. (You chose rock)",
        },
        {
          command: "!leaderboard",
          description: "Example: !leaderboard\nOutput: Displays the top 10 players based on points, wins, and losses in the Rock Paper Scissors game.",
        },
        {
          command: "!reset",
          description: "Example: !reset\nOutput: Resets the scores of all players in the Rock Paper Scissors game. (Admin only)",
        },
        {
          command: "!reset @username:",
          description: "Example: !reset @JohnDoe\nOutput: Resets the scores of the specified user in the Rock Paper Scissors game. (Admin only)",
        },
      ],
      additionalFeatures: [
        {
          title: "Periodic Random YouTube Video",
          description: "The bot periodically posts a random YouTube video from TheCyberFlash's YouTube channel.",
        },
        {
          title: "Coming Soon",
          description: "Stay tuned for more exciting features coming soon!",
        },
        // Add more additional features specific to Bez Leda bot
      ],
      
    faq: [
        {
          question: "Is the Bez Leda Discord Bot available on other servers?",
          answer: "No, the Bez Leda Discord Bot is exclusive to TheCyberFlash's server and not available on other servers.",
        },
        {
          question: "How can I access the bot on TheCyberFlash's server?",
          answer: "To access the Bez Leda Discord Bot on TheCyberFlash's server, join the server using this invitation link: https://discord.gg/4reyQukWsJ",
        },
        {
            question: "Can I add my own games to the list?",
            answer: "Yes, if you are an admin, you can use the command !games add to add new games to the list.",
          },
        {
          question: "How can I participate in the Rock Paper Scissors game?",
          answer: "You can participate in the Rock Paper Scissors game by using the command !rps followed by your choice of rock, paper, or scissors.",
        },
        {
          question: "Is there a leaderboard for the Rock Paper Scissors game?",
          answer: "Yes, you can use the command !leaderboard to view the top 10 players based on points, wins, and losses in the Rock Paper Scissors game on TheCyberFlash's server.",
        },
      ],

      conclusion: {
        title: "Conclusion",
        content: [
          "Thank you for exploring the Bez Leda Discord Bot documentation.",
          "We hope you find the bot enjoyable and useful in TheCyberFlash's server.",
          "If you have any questions, feedback, or suggestions, feel free to reach out to TheCyberFlash.",
          "Happy chatting and gaming!",
        ],
      },
      
  };
  
  export default bezLedaData;
  